<template>
  <div class="relative flex flex-col flex-1 h-full home">
    <TransitionGroup tag="ul" name="list" class="absolute right-0 top-8">
      <div v-for="shot in lastShotsFeed" class="item" :key="shot.id">
        <img class="w-40 mb-2" :src="shot.image" />
      </div>
    </TransitionGroup>
    <div
      class="relative flex flex-col items-center w-full mx-auto my-8 lg:mt-24 lg:w-3/4"
    >
      <div
        class="flex items-center justify-center p-4 mb-2 text-left text-white bg-black rounded-2xl"
      >
        <div>
          🔥🔥🔥 Graffiti contest is live, submit you works to twitter and tag
          @thugs_gg!
          <a
            href="https://twitter.com/thugs_gg/status/1675859203896819714?s=20"
            target="_blank"
            class="inline underline"
            >More info.</a
          >
        </div>
        <a href="https://tags.thugs.gg" class="underline whitespace-nowrap"
          >Enter now</a
        >
      </div>
      <video class="rounded-xl" src="/bcbg.mp4" loop controls></video>
    </div>
    <div class="flex justify-center w-full mx-auto mt-auto lg:w-1/2">
      <clique-quote
        :quote="quotes[currentQuoteIndex]"
        author="Francois Dodique"
        @click="selectNextQuote"
      ></clique-quote>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
// @ is an alias to /src
import "nes.css/css/nes.min.css";
import assetsData from "@/json/index.js";
import { ref, computed, inject } from "vue";
import { onMounted } from "vue";
import { useToast } from "vue-toastification";
import CliqueQuote from "@/components/CliqueQuote.vue";

const toast = useToast();

const isMan = ref(true);
const allLayers = Object.keys(assetsData);
const manLayers = allLayers.filter(
  (layer) => !layer.includes("w_") || layer == "backgrounds"
);
const womanLayers = allLayers.filter(
  (layer) => layer.includes("w_") || layer == "backgrounds"
);
const layers = computed(() => (isMan.value ? manLayers : womanLayers));
const enabledLayers = ref({});
const selectedLayers = ref({});
const lockedLayers = ref({ backgrounds: false });
const currentLayersIndex = ref(0);
const presetName = ref("");
const savedPresets = ref([]);
const layerProbabilities = ref({});
const selectedPreset = ref(0);

const isEditMode = ref(false);

const player = inject("$player");
const isPlaying = ref(false);

const play = () => {
  player.play();
  isPlaying.value = true;
};

const stop = () => {
  player.pause();
  isPlaying.value = false;
};

const quotes = [
  'GM, partner. If you are seeing this, you are early. <a href="https://twitter.com/thugs_gg" target="_blank" class="underline">@thugs_gg</a>',
];

const currentQuoteIndex = ref(Math.floor(Math.random() * quotes.length));

// const selectRandomQuote = () => {
//   currentQuoteIndex.value = Math.floor(Math.random() * quotes.length)
// }

const selectNextQuote = () => {
  currentQuoteIndex.value = (currentQuoteIndex.value + 1) % quotes.length;
};

setInterval(selectNextQuote, 100000);

const choobricks = ref([]);
const selectedChoobrick = ref(null);

const loopLength = ref(0.67349 * 2);
const loopInterval = ref(null);
const loopKey = ref(0);
const loopValue = ref(0);

const HISTORY_SIZE = 100;
const traitHistory = ref([]);
const traitHistoryIndex = ref(0);

const saveChoobrick = () => {
  fetch("/api/choobrick", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      enabledLayers: enabledLayers.value,
      selectedLayers: selectedLayers.value,
      presetName: presetName.value,
      choobrickName: presetName.value + new Date().getTime(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      choobricks.value.push(data);
    });
};

const removeChoobrick = (choobrick) => {
  window.confirm("Are you sure you want to delete this choobrick?") &&
    fetch(`/api/choobrick/${choobrick._id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then(() => {
        choobricks.value = choobricks.value.filter(
          (c) => c._id !== choobrick._id
        );
      });
};

const savePreset = () => {
  const preset = {
    enabledLayers: enabledLayers.value,
    selectedLayers: selectedLayers.value,
    lockedLayers: lockedLayers.value,
    layerProbabilities: layerProbabilities.value,
    presetName: presetName.value,
    isMan: isMan.value,
  };

  fetch("/api/save-preset", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(preset),
  })
    .then((res) => res.json())
    .then((res) => {
      savedPresets.value.push(res);
      selectedPreset.value = savedPresets.value.length - 1;
    });
};

const viewChoobrick = (choobrick) => {
  presetName.value = choobrick.presetName;
  selectedChoobrick.value = choobrick.choobrickName;
  selectedPreset.value = savedPresets.value.findIndex(
    (preset) => preset.presetName === choobrick.presetName
  );
  loadPreset();
  enabledLayers.value = { ...choobrick.enabledLayers };
  selectedLayers.value = { ...choobrick.selectedLayers };
};

// const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

const loadPreset = () => {
  if (!savedPresets.value[selectedPreset.value]) return;
  const preset = savedPresets.value[selectedPreset.value];
  enabledLayers.value = preset.enabledLayers;
  selectedLayers.value = preset.selectedLayers;
  lockedLayers.value = preset.lockedLayers;
  layerProbabilities.value = preset.layerProbabilities;
  presetName.value = preset.presetName;
  isMan.value = preset.isMan;
};

const removePreset = () => {
  //remove preset at backend
  window.confirm("Are you sure you want to remove this preset?") &&
    fetch("/api/preset/" + savedPresets.value[selectedPreset.value]._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        savedPresets.value.splice(selectedPreset.value, 1);
        selectedPreset.value = 0;
        console.log(res);
      });
  //loadPresets()
};

const startLoop = () => {
  stopLoop();
  play();
  selectedChoobrick.value = null;
  randomize();
  loopValue.value = 100;
  loopInterval.value = setInterval(() => {
    randomize();
    loopValue.value = 0;
    loopKey.value++;
    setTimeout(() => {
      loopValue.value = 100;
    }, 50);
  }, parseFloat(loopLength.value) * 1000);
  // document.getElementsByClassName("content")[0].classList.add("looping");
  // document.getElementsByClassName("bg")[0].classList.add("looping");
};

const stopLoop = () => {
  stop();
  clearInterval(loopInterval.value);
  loopValue.value = 0;
  document.getElementsByClassName("content")[0].classList.remove("looping");
  document.getElementsByClassName("bg")[0].classList.remove("looping");
};

const toggleAllLayers = () => {
  const allEnabled = Object.values(enabledLayers.value).every(Boolean);
  layers.value.forEach((layer) => (enabledLayers.value[layer] = !allEnabled));
};

const toggleLockLayer = (layer) => {
  lockedLayers.value[layer] = !lockedLayers.value[layer];
};

const viewNextLayers = () => {
  currentLayersIndex.value =
    (currentLayersIndex.value + 1) % layers.value.length;
};

const viewPrevLayers = () => {
  currentLayersIndex.value =
    (currentLayersIndex.value + layers.value.length - 1) % layers.value.length;
};

const viewPrevious = () => {
  if (traitHistoryIndex.value > 0) {
    traitHistoryIndex.value--;
    const showLayer = traitHistory.value[traitHistoryIndex.value];
    selectedLayers.value = showLayer.selectedLayers;
    enabledLayers.value = showLayer.enabledLayers;
    lockedLayers.value = showLayer.lockedLayers;
    layerProbabilities.value = showLayer.layerProbabilities;
    isMan.value = showLayer.isMan;
  }
};

const viewNext = () => {
  if (traitHistoryIndex.value < traitHistory.value.length - 2) {
    traitHistoryIndex.value++;
    const showLayer = traitHistory.value[traitHistoryIndex.value];
    selectedLayers.value = showLayer.selectedLayers;
    enabledLayers.value = showLayer.enabledLayers;
    lockedLayers.value = showLayer.lockedLayers;
    layerProbabilities.value = showLayer.layerProbabilities;
    isMan.value = showLayer.isMan;
  } else {
    randomize();
  }
};

const edit = () => {
  isEditMode.value = !isEditMode.value;
};

// eslint-disable-next-line no-unused-vars
const mint = () => {
  toast("Not implemented");
};

const fixOverlappingLayers = () => {
  const MASK_LAYER = isMan.value ? "masks" : "w_masks";
  const ICE_LAYER = isMan.value ? "ice" : "w_ice";
  const HATS_LAYER = isMan.value ? "hats_n_face" : "w_hats_n_face";
  const MOUTH_LAYER = isMan.value ? "mouth" : "w_mouth";
  const GLASSES_LAYER = isMan.value ? "glasses" : "w_glasses";
  const HAIR_LAYER = isMan.value ? "hair" : "w_hair";
  const WEARS_LAYER = isMan.value ? "wears" : "w_wears";
  const WEARS_EFFECTS_LAYER = isMan.value ? "wears_effect" : "w_wears_effect";
  const WEARS_WITH_EFFECTS = ["hoodie", "sweetshot", "kruger", "red-copy"];
  const DIRT_EFFECTS = "shirt_";
  if (
    assetsData.ice[selectedLayers.value[ICE_LAYER]] &&
    assetsData.ice[selectedLayers.value[ICE_LAYER]].includes(
      "big_headphones_on_shoulders"
    ) &&
    !assetsData.ice[selectedLayers.value[ICE_LAYER]].includes(
      "big_headphones_on_shoulders_for_hoodie"
    ) &&
    assetsData.wears[selectedLayers.value[WEARS_LAYER]].includes("hoodie")
  ) {
    selectedLayers.value[ICE_LAYER]++;
  }
  if (
    assetsData.ice[selectedLayers.value[ICE_LAYER]] &&
    assetsData.ice[selectedLayers.value[ICE_LAYER]].includes(
      "shoulders_for_hoodie"
    ) &&
    !assetsData.wears[selectedLayers.value[WEARS_LAYER]].includes("hoodie")
  ) {
    selectedLayers.value[ICE_LAYER]--;
  }

  if (
    assetsData.wears[selectedLayers.value[WEARS_LAYER]] &&
    assetsData.wears[selectedLayers.value[WEARS_LAYER]].includes("hoodie")
  ) {
    enabledLayers.value[HAIR_LAYER] = false;
  }

  if (
    enabledLayers.value[WEARS_EFFECTS_LAYER] &&
    !assetsData.wears_effect[selectedLayers.value.wears_effect].includes(
      DIRT_EFFECTS
    )
  ) {
    enabledLayers.value[WEARS_EFFECTS_LAYER] = false;
    for (let WEAR of WEARS_WITH_EFFECTS) {
      enabledLayers.value[WEARS_EFFECTS_LAYER] =
        enabledLayers.value[WEARS_EFFECTS_LAYER] ||
        assetsData.wears[selectedLayers.value.wears].includes(WEAR);
    }
  }
  if (enabledLayers.value[MASK_LAYER]) {
    if (!lockedLayers.value[HATS_LAYER])
      enabledLayers.value[HATS_LAYER] = false;
    if (!lockedLayers.value[MOUTH_LAYER])
      enabledLayers.value[MOUTH_LAYER] = false;
    if (!lockedLayers.value[GLASSES_LAYER])
      enabledLayers.value[GLASSES_LAYER] = false;
    if (!lockedLayers.value[HAIR_LAYER])
      enabledLayers.value[HAIR_LAYER] = false;
  }
  if (enabledLayers.value[HATS_LAYER]) {
    if (!lockedLayers.value[HAIR_LAYER])
      enabledLayers.value[HAIR_LAYER] = false;
  }
};

const randomize = (changeSex = true) => {
  //push current state to history

  if (traitHistory.value.length > HISTORY_SIZE) {
    traitHistory.value.shift();
  }

  isMan.value = changeSex ? Math.random() < 0.5 : isMan.value;

  for (let layer of layers.value) {
    if (!lockedLayers.value[layer]) {
      selectedLayers.value[layer] = Math.floor(
        Math.random() * assetsData[layer].length
      );
      enabledLayers.value[layer] =
        Math.random() < layerProbabilities.value[layer] / 100;
    }
  }
  fixOverlappingLayers();
  traitHistory.value.push({
    enabledLayers: { ...enabledLayers.value },
    selectedLayers: { ...selectedLayers.value },
    lockedLayers: { ...lockedLayers.value },
    layerProbabilities: { ...layerProbabilities.value },
    presetName: presetName.value,
    isMan: isMan.value,
  });

  traitHistoryIndex.value = traitHistory.value.length - 1;
};

const lastShotsFeed = ref([]);

const MAX_SHOTS = 4;

const download = async () => {
  // eslint-disable-next-line no-unused-vars
  const saveTraits = () => {
    const traits = {};
    for (let layer of layers.value) {
      traits[layer] = {
        enabled: enabledLayers.value[layer],
        value: assetsData[layer][selectedLayers.value[layer]],
        valueIndex: selectedLayers.value[layer],
      };
    }

    return traits;
  };

  const destroyClickedElement = (event) => {
    document.body.removeChild(event.target);
  };

  // eslint-disable-next-line no-unused-vars
  const saveObjectAsTextFile = (fileName, text) => {
    const textFileAsBlob = new Blob([JSON.stringify(text)], {
      type: "text/plain",
    });
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.innerHTML = "Download File";
    if (window.webkitURL != null) {
      // Chrome allows the link to be clicked
      // without actually adding it to the DOM.
      downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    } else {
      // Firefox requires the link to be added to the DOM
      // before it can be clicked.
      downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
      downloadLink.onclick = destroyClickedElement;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
    }

    downloadLink.click();
  };
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = 600;
  canvas.height = 675;
  ctx.imageSmoothingEnabled = false;
  ctx.font = `normal 16px "Press Start 2P"`;
  ctx.fillStyle = "white";
  let img = new Image();
  img.src = `/vinette.png`;
  await new Promise((resolve) => {
    img.onload = () => {
      ctx.drawImage(img, 0, 0, 600, 675);
      resolve();
    };
  });
  img = new Image();
  img.src = `/logo.png`;
  await new Promise((resolve) => {
    img.onload = () => {
      ctx.drawImage(img, 25, 610, 50, 50);
      resolve();
    };
  });

  for (let layer of layers.value) {
    if (enabledLayers.value[layer]) {
      const img = new Image();
      img.src = `/assets/${layer}/${
        assetsData[layer][selectedLayers.value[layer]]
      }`;
      await new Promise((resolve) => {
        img.onload = () => {
          ctx.drawImage(img, 25, 25, 550, 550);
          resolve();
        };
      });
    }
  }
  ctx.fillText("thugs.gg", 380, 650);
  const link = document.createElement("a");
  const id = new Date().getTime().toString(36);
  ctx.fillText(id, 25, 650);
  link.download = presetName.value + id + ".png";
  link.href = canvas.toDataURL();
  const img3 = new Image();
  img3.src = link.href;
  img3.onload = () => {
    lastShotsFeed.value.unshift({
      id,
      name: presetName.value,
      image: link.href,
    });
    if (lastShotsFeed.value.length > MAX_SHOTS) {
      lastShotsFeed.value.pop();
    }
  };
  //appnd canvas to page
  // document.body.appendChild(canvas);
  //link.click();
  //saveObjectAsTextFile(presetName.value + id + ".json", saveTraits());
};

let cards = document.getElementsByClassName("card");
let renderRotate, clearRotate;

const shadowBlur = 20,
  shadowSpread = 2,
  shadowXOffset = 0,
  shadowYOffset = 0;

renderRotate = function (event) {
  let contentEl = this.children[0];
  let coorX = event.offsetX;
  let coorY = event.offsetY;
  let w = this.offsetWidth;
  let h = this.offsetHeight;

  /*
  ~ - binary NOT, also used for fast rounding
  (w/2-coorX) - symmetricity
  (80/w) - we need to decrease the symmetricity subtraction depending on element width/height
  /10 - we use degrees so we need one decimals
  */
  let posX = ~((w / 2 - coorX) * (80 / w)) / 10;
  let posY = ~~((h / 2 - coorY) * (80 / h)) / 10;

  // used for transition on mouse leave
  if (this.children[0].classList.contains("onLeave")) {
    contentEl.classList.remove("onLeave");
  }

  contentEl.style.transform =
    "rotateX(" + posY + "deg) rotateY(" + posX + "deg)";
  contentEl.style.boxShadow =
    -2 * posX +
    shadowXOffset +
    "px" +
    " " +
    (2 * posY + shadowYOffset) +
    "px " +
    shadowBlur +
    "px " +
    shadowSpread +
    "px";
};

clearRotate = function () {
  let contentEl = this.children[0];
  contentEl.style.transform = "none";
  contentEl.style.boxShadow =
    shadowXOffset +
    "px " +
    shadowYOffset +
    "px " +
    shadowBlur +
    "px " +
    shadowSpread +
    "px";
  contentEl.classList.add("onLeave");
};

onMounted(() => {
  for (let i = 0; i < cards.length; i++) {
    cards[i].addEventListener("mousemove", renderRotate);
    cards[i].addEventListener("mouseleave", clearRotate);
  }
});
</script>

<style lang="sass">

.card
  perspective: 500px
  color: rgba(0,0,0,.2)
  >.onLeave
    transition: transform .3s ease


.list-move,
.list-enter-active,
.list-leave-active
  transition: all 0.5s ease


.list-enter-from,
.list-leave-to
  opacity: 0
  transform: translateX(30px)


/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active
  position: absolute


.fade-enter-active,
.fade-leave-active
  transition: opacity 0.5s ease


.fade-enter-from,
.fade-leave-to
  opacity: 0

.preview
  // width: 550px
  // height: 550px
  // min-width: 550px
  // min-height: 550px
canvas, .pixelated
  // width: 550px
  // height: 550px
  image-rendering: pixelated
  image-rendering: crisp-edges
.progress-transition
  &::-webkit-progress-value
    transition: width var(--loopLength) linear
</style>
