import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./index.css";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import ListPlayer from "listplayer";
import { VagmiPlugin, configureChains, createClient, chain  } from 'vagmi';
import { infuraProvider } from 'vagmi/providers/infura';
import { publicProvider } from 'vagmi/providers/public';

const infuraKey = process.env.INFURA_ID;

const supportedChains =
  process.env.NEXT_PUBLIC_NETWORK === 'goerli'
    ? [chain.goerli]
    : [chain.mainnet];

export const { chains, provider, webSocketProvider } = configureChains(
  supportedChains,
  [
    infuraProvider({
      apiKey: infuraKey,
    }),
    publicProvider(),
  ]
);

export const client = createClient({
    appName: 'thugs.gg',
    chains,
    autoConnect: true,
    provider,
  });

const _app = createApp(App).use(store).use(router).use(Toast)
_app.provide('$player', new ListPlayer({
    tracks: ['/broadbay.mp3'],
    loopTracks: true,
    progressThroughTracks: true
  }))
_app.use(VagmiPlugin(client));
_app.mount("#app");
