<template>
  <div
    class="max-h-screen overflow-auto text-white wrapper bg-zinc-900"
    style="transform: translateZ(0)"
    @scroll="scrollOffset = $event.target.scrollTop"
    :style="`--scrollOffset: ${-scrollOffset / 2};`"
  >
    <div class="overflow-hidden bg">
      <div class="flex flex-col min-h-screen p-4 lg:p-8 content">
        <div class="flex flex-col items-center justify-between md:flex-row">
          <div
            @mouseenter="selectRandomSlogan"
            @mouseleave="shownSloganIndex = null"
            class="flex flex-col items-center justify-center cursor-pointer"
          >
            <img src="@/assets/logo_4.png" class="w-72" />
            <div class="flex text-lg">
              how to&nbsp;
              <transition name="slide-up" mode="out-in">
                <div class="w-20" v-if="!shownSloganIndex">****</div>
                <div class="w-20" v-else>{{ slogans[shownSloganIndex] }}</div>
              </transition>
            </div>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const shownSloganIndex = ref(null);

// 4 letter slogans for title: how to ****
const slogans = [
  `vibe`,
  `hype`,
  `pump`,
  `ride`,
  `shot`,
  `roll`,
  `play`,
  `slap`,
  `trap`,
  `leet`,
  `gain`,
  `flex`,
  `flip`,
  `loot`,
  `moon`,
  `push`,
  `drop`,
];

const selectRandomSlogan = () => {
  const randomIndex = Math.floor(Math.random() * slogans.length);
  shownSloganIndex.value = randomIndex;
};

// var winPos;
window.scrollTo(15, 15);

// var flag = false;
document.querySelector("body").addEventListener("click", function (e) {
  var div = document.createElement("div");
  div.className = "boom";

  document.querySelector("body").appendChild(div);
  div.style.left = e.pageX + "px";
  div.style.top = e.pageY + "px";
  var maxElems = 16;
  for (let i = 0; i < maxElems; i++) {
    var span = document.createElement("span");
    var newSpan = div.appendChild(span);
    var deg = i * (360 / maxElems) + Math.floor(Math.random() * 15);
    var height = 20 + Math.floor(Math.random() * 30);
    var width = 4 + Math.floor(Math.random() * 10);
    newSpan.style.height = height + "px";
    newSpan.style.width = width + "px";
    newSpan.style.transform = "rotate(" + deg + "deg)";
  }
  window.requestAnimationFrame(function () {
    Array.from(div.querySelectorAll("span")).forEach((el) => {
      var trasY = -50 - Math.floor(Math.random() * 100);
      el.style.transform += "scaleY(0.5) translateY(" + trasY + "px)";
      el.style.opacity = "0";
    });
    window.setTimeout(function () {
      document.body.removeChild(div);
    }, 400);
  });
  // if (!flag) {
  //   flag = true;
  //   winPos = [document.body.scrollLeft, document.body.scrollTop];
  //   var maxShakeOffset = 1;
  //   var shake = window.setInterval(function () {
  //     var shakeOffset = Math.floor(Math.random() * 10 * maxShakeOffset);
  //     window.scrollTo(
  //       winPos[0] - maxShakeOffset / 2 + shakeOffset,
  //       winPos[1] - maxShakeOffset / 2 + shakeOffset
  //     );
  //   }, 10);
  //   window.setTimeout(function () {
  //     window.clearInterval(shake);
  //     window.scrollTo(winPos[0], winPos[1]);
  //     flag = false;
  //   }, 200);
  // }
});
</script>

<style lang="scss">
html {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABFklEQVRYR9WXURLDIAhE6/0PbSdOtUpcd1Gnpv1KGpTHBpCE1/cXq+vrMph7dGvXZTtpfW10DCA5jrH1H0Jhs5E0hnZdCR+vb5S8Nn8mQCeS9BdSalYJqMBjAGzq59xAESN7VFVUgV8AZB/dZBR7QTFDCqGquvUBVVoEtgIwpQRzmANSFHgWQKExHdIrPeuMvQNDarXe6nC/AutgV3JW+6bgqQLeV8FekRtgV+ToDKEKnACYKsfZjjkam7a0ZpYTytwmgainpC3HvwBocgKOxqRjehoR9DFKNFYtOwCGYCszobeCbl26N6yyQ6g8X/Wex/rBPsNEV6qAMaJPMynIHQCoSqS9JSMmwef51LflTgCRszU7DvAGiV6mHWfsaVUAAAAASUVORK5CYII=),
    auto;
}
#app {
  font-family: "Press Start 2P", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.bg {
  will-change: transform;
  transform: translateZ(0);
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("~assets/pattern.png");
    background-size: calc(1.5 * 654px) auto;
    background-position-y: calc(1px * var(--scrollOffset));
    z-index: 1;
  }
  &.looping {
    &::before {
      animation: vibe 0.67349s linear infinite;
      animation-direction: reverse;
    }
  }
  position: relative;
  z-index: 2;
}
.content {
  position: relative;
  z-index: 2;
  &.looping {
    animation: vibe 0.67349s linear infinite;
  }
}

@keyframes vibe {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-1px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0px);
  }
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.15s ease;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(15px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
}
div.boom {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
span {
  display: block;
  width: 4px;
  height: 50px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform-origin: center bottom;
  transition: transform 0.2s, opacity 0.45s;
  transition-timing-function: ease-out;
}
</style>
