import backgrounds from './backgrounds.json';
import eye_state from './eye_state.json';
import eyes from './eyes.json';
import face_state from './face_state.json';
import facial_hair from './facial_hair.json';
import grug from './grug.json';
import hair from './hair.json';
import hats_n_face from './hats_n_face.json';
import ice from './ice.json';
import masks from './masks.json';
import glasses from './glasses.json';
import mouth from './mouth.json';
import skins from './skins.json';
import wears from './wears.json';
import wears_effect from './wears_effect.json';
import w_eye_state from './w_eye_state.json';
import w_eyes from './w_eyes.json';
import w_face_state from './w_face_state.json';
import w_facial_hair from './w_facial_hair.json';
import w_grug from './w_grug.json';
import w_hair from './w_hair.json';
import w_hats_n_face from './w_hats_n_face.json';
import w_ice from './w_ice.json';
import w_masks from './w_masks.json';
import w_glasses from './w_glasses.json';
import w_mouth from './w_mouth.json';
import w_skins from './w_skins.json';
import w_wears from './w_wears.json';
import w_wears_effect from './w_wears_effect.json';
import w_makeup from './w_makeup.json';

export default {
    backgrounds,
    skins,
    w_skins,
    w_makeup,
    eye_state,
    eyes,
    face_state,
    hair,
    grug,
    w_grug,
    wears,
    wears_effect,
    ice,
    facial_hair,
    hats_n_face,
    glasses,
    masks,
    mouth,
    w_eye_state,
    w_eyes,
    w_face_state,
    w_hair,
    w_wears,
    w_wears_effect,
    w_ice,
    w_facial_hair,
    w_hats_n_face,
    w_glasses,
    w_masks,
    w_mouth,
    
}